import React from 'react';
import PropTypes from 'prop-types';
import { Link, Stack, Typography } from '@mui/material';

const ArtistsName = ({ artists, linkColor, underline }) => {
  let producers = artists.filter(
    (artist) => artist.role === 'producer' || artist.role === 'ID'
  );
  const feat = artists.filter((artist) => artist.role === 'feat');

  if (producers.length === 0 && feat.length === 0) {
    producers = artists;
  }

  return (
    <Stack spacing={{ xs: 0, md: 1 }} direction={'column'}>
      {producers.map((artist, index) => (
        <Stack key={artist.artist_meta?.id} direction={'row'} spacing={1}>
          <Link
            href=""
            to={`/artists/${artist.artist_meta?.id}`}
            sx={{
              color: linkColor || '#fff',
              textDecorationColor: linkColor || '#fff',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
            underline={underline || 'always'}
          >
            {artist.artist_meta?.name}
          </Link>
          {index < producers.length - 1 && (
            <Typography variant="caption" sx={{ color: linkColor || '#fff' }}>
              &
            </Typography>
          )}
        </Stack>
      ))}
      {feat.length > 0 && (
        <Stack direction={'row'} spacing={1}>
          <Typography variant="caption" sx={{ color: linkColor || '#fff' }}>
            feat.
          </Typography>
          {feat.map((artist, index) => (
            <Stack key={artist.artist_meta?.id} direction={'row'} spacing={1}>
              <Link
                href=""
                to={`/artists/${artist.artist_meta?.id}`}
                sx={{
                  color: linkColor || '#fff',
                  textDecorationColor: linkColor || '#fff',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
                underline={underline || 'always'}
              >
                {artist.artist_meta?.name}
              </Link>
              {index < feat.length - 1 && (
                <Typography
                  variant="caption"
                  sx={{ color: linkColor || '#fff' }}
                >
                  &
                </Typography>
              )}
            </Stack>
          ))}
        </Stack>
      )}
    </Stack>
  );
};

ArtistsName.propTypes = {
  artists: PropTypes.array,
  linkColor: PropTypes.string,
  underline: PropTypes.string,
};

export default ArtistsName;
