import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  IconButton,
  Popper,
  Card,
  Fade,
  CardHeader,
  CardContent,
  Button,
  Stack,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
  Avatar,
  styled,
  ClickAwayListener,
  Box,
} from '@mui/material';
import {
  QueueMusic,
  Close,
  DragIndicator,
  Pause,
  PlayArrow,
  AccessTime,
} from '@mui/icons-material';
import { useApi } from '../context/ApiProvider';
import Loading from './Loading';
import ActionsMenu from './ActionsMenu';
import { useSnackbar } from 'notistack';
import { usePlay } from '../context/Play';
import { timeFormat } from '../utils/timeFormat';
import { useScreenSize } from '../utils/useScreenSize';
import playingArt from '../assets/images/bars.gif';

const DragIcon = styled(DragIndicator)(({ theme }) => ({
  position: 'absolute',
  left: '-8px',
  color: theme.palette.secondary,
  ':hover': {
    cursor: 'row-resize',
  },
}));

const PlayingBars = styled(Avatar)(() => ({
  position: 'absolute',
  zIndex: 1,
  top: 25,
  left: 25,
  width: '30px',
  height: '30px',
}));

const QueueItem = ({ item, onRemoveQueueItem }) => {
  const navigate = useNavigate();
  const { isMobile } = useScreenSize();
  const { set_meta, played } = item;
  const [isItemFocus, setIsItemFocus] = useState(false);
  const { isPlaying, playingMetaId, audioRef, playPause, createAudioRef } =
    usePlay();
  const isItemPlaying = playingMetaId === set_meta.id && isPlaying;

  function removeItem() {
    onRemoveQueueItem(item.id);
  }

  function goToSet() {
    navigate(`/sets/${set_meta.id}`);
  }

  const handlePlayPauseClick = async () => {
    const audio =
      isItemPlaying || playingMetaId === set_meta.id
        ? audioRef
        : await createAudioRef(set_meta.id);
    if (audio) {
      playPause(audio);
    }
  };

  return (
    <ListItem
      onMouseEnter={() => setIsItemFocus(isMobile ? false : true)}
      onMouseLeave={() => setIsItemFocus(false)}
      sx={{ position: 'relative', pl: '20px' }}
      id={item.is_current ? 'queue-item-current' : null}
    >
      {isItemFocus && <DragIcon size="small" />}
      <ListItemAvatar>
        {isItemPlaying && !isItemFocus && (
          <PlayingBars src={playingArt} sx={{ bgcolor: 'info.main' }} />
        )}
        {isItemFocus && (
          <IconButton
            sx={{
              position: 'absolute',
              top: set_meta.user_fav?.current_timestamp ? '16px' : '7px',
              left: '18px',
              zIndex: 2,
            }}
            color="primary"
            onClick={handlePlayPauseClick}
          >
            {isItemPlaying ? <Pause /> : <PlayArrow />}
          </IconButton>
        )}
        <Avatar src={set_meta.art_url} />
      </ListItemAvatar>

      <ListItemText
        onClick={goToSet}
        primary={`${set_meta.artist} - ${set_meta.set_name}`}
        primaryTypographyProps={{
          color: played ? 'text.disabled' : 'primary',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          fontSize: '1rem',
        }}
        secondary={
          <>
            {set_meta.user_fav?.current_timestamp && (
              <Typography
                variant="body2"
                component="span"
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  color: 'text.secondary',
                  gap: 0.5,
                }}
              >
                <AccessTime sx={{ fontSize: '1rem' }} />{' '}
                {`${timeFormat(
                  set_meta.user_fav.current_timestamp
                )} / ${timeFormat(set_meta.duration / 1000)}`}
              </Typography>
            )}
          </>
        }
        secondaryTypographyProps={{
          color: played ? 'text.disabled' : 'primary',
        }}
        sx={{ cursor: 'pointer', ':hover': { textDecoration: 'underline' } }}
      />

      <Stack direction="row" alignItems="center">
        <ActionsMenu
          showLikedTracks={false}
          likedTracksOpen={false}
          onClickLikedTracks={() => false}
          isPlaying={isItemPlaying}
          onPlayPauseClick={handlePlayPauseClick}
          setId={set_meta.id}
          onRemoveQueueItem={removeItem}
          isOnQueue
          set={set_meta}
        />
      </Stack>
    </ListItem>
  );
};

QueueItem.propTypes = {
  item: PropTypes.object,
  onRemoveQueueItem: PropTypes.func,
};

const QueuePopup = () => {
  const api = useApi();
  const { isMobile } = useScreenSize();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [queue, setQueue] = useState([]);
  const dragItem = useRef();
  const dragOverItem = useRef();
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (!open) {
      if (isMobile) document.body.style.overflow = 'scroll';
      return;
    }
    if (isMobile) document.body.style.overflow = 'hidden';
    pullQueue();
  }, [open]);

  async function onRemoveQueueItem(queueItemId) {
    const response = await api.delete(`/queue/${queueItemId}`);
    if (!response.ok) {
      enqueueSnackbar('Something went wrong, please try again!', {
        variant: 'error',
      });
    } else {
      pullQueue();
    }
  }

  async function pullQueue() {
    setLoading(true);
    const response = await api.get('/queue');
    if (response.ok) {
      setQueue(serializeQueue(response.body));
      setTimeout(() => {
        const currentItem = document.querySelector('#queue-item-current');
        if (currentItem) {
          currentItem.scrollIntoView();
        }
      }, 50);
    } else {
      console.log(response);
    }
    setLoading(false);
  }

  async function handleClearQueue() {
    setLoading(true);
    const response = await api.delete('/queue');
    if (response.ok) {
      setQueue([]);
    } else {
      console.log(response);
    }
    setLoading(false);
  }

  function serializeQueue(queue) {
    const currentItem = queue.find((item) => item.is_current);
    if (!currentItem) return queue;
    const currentItemPos = currentItem.order - 1;
    return queue.map((item, index) => {
      if (index < currentItemPos) {
        return { ...item, played: true };
      } else {
        return { ...item, played: false };
      }
    });
  }

  const handleClick = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  function allowDrag(e) {
    e.preventDefault();
  }

  function dragStart(e, index) {
    dragItem.current = index;
  }

  function dragEnter(e, index) {
    dragOverItem.current = index;
  }

  function drop() {
    if (dragItem.current !== dragOverItem.current) {
      const item = queue[dragItem.current];
      if (item) {
        reorderQueueItem(item, dragOverItem.current);
      }
    }
  }

  async function reorderQueueItem(item, newPosition) {
    setLoading(true);
    const response = await api.put('/queue', {
      set_meta_id: item.set_meta_id,
      order: newPosition + 1,
      is_current: item.is_current,
      user_id: item.user_id,
      id: item.id,
    });
    if (!response.ok) {
      enqueueSnackbar('Something went wrong, please try again!', {
        variant: 'error',
      });
    } else {
      pullQueue();
    }
  }

  return (
    <div>
      <IconButton onClick={handleClick} ref={anchorRef}>
        <QueueMusic />
      </IconButton>

      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        sx={{
          zIndex: 9999,
          width: { xs: '95%', sm: 480 },
        }}
        placement="top"
        modifiers={[
          {
            name: 'preventOverflow',
            enabled: true,
            options: {
              altAxis: true,
              altBoundary: true,
              tether: true,
              rootBoundary: 'document',
              padding: 8,
            },
          },
        ]}
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Card
              sx={{
                border: 1,
                p: 0,
                width: { xs: '100%', sm: 480 },
                minWidth: { xs: '100%', sm: 480 },
                height: { xs: '60vh', sm: 660 },
                margin: { xs: 'auto', sm: '0 20px 30px -20px' },
              }}
            >
              <ClickAwayListener onClickAway={handleClose}>
                <Box sx={{ position: 'relative', height: '100%' }}>
                  <CardHeader
                    sx={{ p: 1.5, borderBottom: 1, borderColor: 'divider' }}
                    action={
                      <Stack direction="row" alignItems="center" spacing={1}>
                        <Button
                          variant="outlined"
                          size="small"
                          sx={{ minWidth: 'unset', height: '30px' }}
                          onClick={handleClearQueue}
                        >
                          Clear
                        </Button>
                        <IconButton aria-label="settings" onClick={handleClose}>
                          <Close />
                        </IconButton>
                      </Stack>
                    }
                    subheader="Queue"
                  />
                  <CardContent
                    sx={{ overflow: 'scroll', pl: 1, height: '90%' }}
                  >
                    {loading ? (
                      <Stack marginTop={10}>
                        <Loading />
                      </Stack>
                    ) : (
                      <List onDrop={drop} onDragOver={allowDrag}>
                        {queue.map((item, index) => (
                          <div
                            draggable={true}
                            onDragStart={(e) => dragStart(e, index)}
                            onDragEnter={(e) => dragEnter(e, index)}
                            onDragOver={(e) =>
                              (e.currentTarget.style.borderTop =
                                '1px dashed #DEFF7B')
                            }
                            onDragLeave={(e) =>
                              (e.currentTarget.style.borderTop = '')
                            }
                            key={item.id}
                          >
                            <QueueItem
                              item={item}
                              onRemoveQueueItem={onRemoveQueueItem}
                            />
                          </div>
                        ))}
                      </List>
                    )}
                  </CardContent>
                </Box>
              </ClickAwayListener>
            </Card>
          </Fade>
        )}
      </Popper>
    </div>
  );
};

QueuePopup.propTypes = {};

export default QueuePopup;
