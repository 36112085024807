import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Tab, Tabs, Typography } from '@mui/material';

const TabsContainer = ({ tabTitles, tabContent }) => {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
      sx: { textTransform: 'none' },
    };
  }

  function TabContainer(props) {
    // eslint-disable-next-line react/prop-types
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`tabpanel-${index}`}
        aria-labelledby={`tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ padding: { xs: '0 20px', lg: '10px 0' } }}>{children}</Box>
        )}
      </div>
    );
  }

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="auto"
        >
          {tabTitles.map((tabTitle, index) => (
            <Tab
              label={
                <Typography>
                  {tabTitle.title}{' '}
                  {Boolean(tabTitle.count) && (
                    <Typography
                      variant="caption"
                      fontSize={16}
                      fontWeight={'bold'}
                    >
                      ( {tabTitle.count} )
                    </Typography>
                  )}
                </Typography>
              }
              key={`${tabTitle.title}-${index}`}
              {...a11yProps(index)}
            />
          ))}
        </Tabs>
      </Box>
      {tabContent.map((content, index) => (
        <TabContainer value={value} key={index} index={index}>
          {content}
        </TabContainer>
      ))}
    </Box>
  );
};

TabsContainer.propTypes = {
  tabTitles: PropTypes.arrayOf(PropTypes.string).isRequired,
  tabContent: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default TabsContainer;
