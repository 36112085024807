import React from 'react';
import PropTypes from 'prop-types';
import defaultArt from '../assets/images/dj_kip_art.png';
import {
  Card,
  CardContent,
  CardHeader,
  CardActionArea,
  Paper,
  Avatar,
  Badge,
  Chip,
  IconButton,
  ListItem,
  CardActions,
} from '@mui/material';
import {
  Favorite,
  FavoriteBorder,
  Star,
  StarBorder,
} from '@mui/icons-material';

const MobileCard = ({
  handleFavoriteClick,
  handleLikeClick,
  isFav,
  showLikes,
  date,
  likes,
  goToItem,
  img,
  title,
  subtitle,
  subtitleExtra,
  chips,
  children,
  cardStyles = {},
  isTrackListItem = false,
  trackPosition,
  isTrackPlaying,
  playingArt,
}) => {
  const filteredChips = chips.filter((g) => Boolean(g));
  return (
    <>
      <Card
        sx={{
          overflow: 'visible',
          backgroundColor: 'info.dark',
          ':hover': { backgroundColor: 'info.main' },
          ...cardStyles,
        }}
      >
        <CardHeader
          action={
            <>
              <IconButton aria-label="favorite" onClick={handleFavoriteClick}>
                {isFav ? <Star /> : <StarBorder />}
              </IconButton>
              <IconButton aria-label="favorite" onClick={handleLikeClick}>
                {showLikes ? (
                  <Badge color="primary" badgeContent={likes} max={999}>
                    <Favorite />
                  </Badge>
                ) : (
                  <FavoriteBorder />
                )}
              </IconButton>
            </>
          }
          title={date}
          titleTypographyProps={{ fontSize: '1rem' }}
          sx={{ paddingBottom: 0 }}
        />
        <CardActionArea onClick={goToItem}>
          {isTrackListItem ? (
            <CardHeader
              avatar={
                <Badge
                  color="primary"
                  badgeContent={`#${trackPosition}`}
                  max={999}
                  anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                  sx={{ ml: 1 }}
                >
                  {isTrackPlaying ? (
                    <Avatar
                      src={playingArt}
                      sx={{ bgcolor: 'info.main', width: 56, height: 56 }}
                    ></Avatar>
                  ) : (
                    <Avatar src={img} sx={{ width: 56, height: 56 }} />
                  )}
                </Badge>
              }
              title={title}
              subheader={
                <>
                  {subtitle} <br />
                  {subtitleExtra ? `[${subtitleExtra}]` : ''}
                </>
              }
            />
          ) : (
            <CardHeader
              avatar={
                <Avatar
                  src={img || defaultArt}
                  sx={{ width: 56, height: 56 }}
                />
              }
              title={title}
              subheader={
                <>
                  {subtitle} <br />
                  {subtitleExtra ? `[${subtitleExtra}]` : ''}
                </>
              }
              sx={{ paddingTop: '5px' }}
            />
          )}
          <CardContent sx={{ py: 0 }}>
            <Paper
              sx={{
                display: 'flex',
                justifyContent: 'flex-start',
                flexWrap: 'no-wrap',
                listStyle: 'none',
                p: 0,
                m: 0,
                backgroundColor: 'transparent',
                boxShadow: 'none',
                backgroundImage: 'none',
                overflow: 'auto',
                scrollbarWidth: 'none' /* Hide scrollbar track (Firefox) */,
                msOverflowStyle: 'none' /* Hide scrollbar track (IE, Edge) */,
              }}
              component="ul"
            >
              {filteredChips.slice(0, 3).map((g, i) => (
                <ListItem
                  key={g}
                  sx={{ width: 'unset', padding: '0 8px 8px 0' }}
                >
                  <Chip
                    color={i % 2 === 0 ? 'secondary' : 'default'}
                    label={g}
                  />
                </ListItem>
              ))}
            </Paper>
          </CardContent>
        </CardActionArea>
        <CardActions sx={{ justifyContent: 'center' }}>{children}</CardActions>
      </Card>
    </>
  );
};

MobileCard.propTypes = {
  handleFavoriteClick: PropTypes.func,
  handleLikeClick: PropTypes.func,
  goToItem: PropTypes.func,
  isFav: PropTypes.bool,
  showLikes: PropTypes.bool,
  date: PropTypes.string,
  likes: PropTypes.number,
  img: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  subtitleExtra: PropTypes.string,
  chips: PropTypes.array,
  children: PropTypes.node,
  cardStyles: PropTypes.object,
  isTrackListItem: PropTypes.bool,
  trackPosition: PropTypes.number,
  isTrackPlaying: PropTypes.bool,
  playingArt: PropTypes.string,
};

export default MobileCard;
